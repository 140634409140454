<template>
  <div class="filmArrangement">
    <van-nav-bar
      title="排名指导明细"
      left-text="返回"
      left-arrow
      @click-left="toTopView()"
    />
    <div class="filter">
      <div>
        <span
          >{{ filter.date.replace('-', '.').replace('-', '.') }} <br />
          {{ filter.film }}</span
        >
      </div>
      <div>
        <van-tabs v-model="active" color="#4c78a9" @change="changeTab">
          <van-tab title="城市排片率"> </van-tab>
          <van-tab title="院线排片率"></van-tab>
          <van-tab title="影管排片率"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div>
      <ul class="chart" v-if="showData.length > 0">
        <li v-for="(item, index) in showData" :key="index">
          <span class="chart-key">{{ item.Name }}</span>
          <span
            class="chart-value"
            :style="{
              width: `${parseFloat(item.SessionByRate).toFixed(2)}%`,
              'max-width': '65%',
            }"
          ></span>
          <span class="chart-number">{{ item.SessionByRate }}%</span>
        </li>
      </ul>
      <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
export default {
  name: 'filmArrangement',
  components: {},
  props: {},
  data() {
    return {
      active: 0,
      filter: {
        date: '2000-01-01',
        film: 'AA',
      },
      showData: [],
      Datas: [],
    }
  },
  computed: {},
  mounted() {
    this.filter.date = this.$route.query.dates
    this.filter.film = this.$route.query.name.substring(0, 4)
    this.getData()
  },
  methods: {
    toTopView() {
      window.history.back()
    },
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101203","cmdArgs":["${this.filter.date}","${this.filter.film}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        this.Datas = r
        // console.log({r});
        this.changeTab()
      })
    },
    changeTab() {
      console.log({ active: this.active })
      switch (this.active) {
        case 1:
          this.showData = this.Datas.chainDatas
          break
        case 0:
          this.showData = this.Datas.cityDatas
          break
        case 2:
          this.showData = this.Datas.investDatas
          break
        default:
          break
      }
    },
  },
  watch: {},

  // 页面周期函数--监听页面加载
  onLoad() {},
  // 页面周期函数--监听页面初次渲染完成
  onReady() {},
  // 页面周期函数--监听页面显示(not-nvue)
  onShow() {},
  // 页面周期函数--监听页面隐藏
  onHide() {},
  // 页面周期函数--监听页面卸载
  onUnload() {},
  // 页面处理函数--监听用户下拉动作
  // onPullDownRefresh() { uni.stopPullDownRefresh(); },
  // 页面处理函数--监听用户上拉触底
  // onReachBottom() {},
  // 页面处理函数--监听页面滚动(not-nvue)
  // onPageScroll(event) {},
  // 页面处理函数--用户点击右上角分享
  // onShareAppMessage(options) {},
}
</script>

<style scoped lang="less">
.filter {
  display: flex;
  margin: 0 auto;
  margin-bottom: 5px;
  font-size: 12px;
  > div {
    &:nth-child(1) {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      background-color: #fff;
      > span {
        background-color: rgb(205, 205, 205);
        display: block;
        width: 100%;
        padding: 5px;
        margin: 0 auto;
        border-radius: 10px;
      }
    }
    &:nth-child(2) {
      flex: 3;
    }
  }
}

.van-nav-bar {
  background: #3d8bff;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
.chart {
  // border: 1px solid #1e131d;
  margin: 5px;
  li {
    margin: 0 2px;
    span {
      font-size: 15px;
      display: inline-block;
      width: 10%;
      height: 23px;
      vertical-align: middle;
      &:first-child {
        width: 20%;
        text-align: center;
        color: #7d807e;
        font-size: 13px;
        transform: translateY(4px);
      }
      &:nth-child(2) {
        background-color: #4c78a9;
        border-radius: 30px;
        width: 40%;
        padding: 10px;
        box-sizing: border-box;
        height: 15px;
      }
      &:nth-child(3) {
        padding-left: 5px;
      }
    }
  }
  &-number {
    font-size: 14px !important;
    padding-top: 2px;
    color: #1e131d;
  }
}
</style>
